import React from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../components/ui/accordion';

const FAQuestion = (props) => {
  const AllQans = Array.isArray(props.data) ? props.data : [props.data];

  return (
    <>
      {/* accordians */}
      <Accordion type="single" collapsible className="w-full">
        {AllQans.map((question, index) => (
          <AccordionItem key={`item-${index + 1}`} value={`item-${index + 1}`}>
            <AccordionTrigger>{question.question}</AccordionTrigger>
            <AccordionContent>
              {question.ans}{' '}
              {question?.midBtn ? (
                <>
                  <a
                    className="font-bold underline max-w-2xl"
                    href={question.midBtn}
                    target=" blank"
                  >
                    here
                  </a>{' '}
                  {question.ans2}{' '}
                </>
              ) : (
                ''
              )}
              {question?.lastBtn ? (
                <>
                  <a
                    className="font-bold max-w-2xl"
                    href={question.lastBtn}
                    target="blank"
                  >
                    here
                  </a>{' '}
                </>
              ) : (
                ''
              )}
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};

export default FAQuestion;
