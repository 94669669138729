import React, { useState } from 'react';
import PageDescriptions from '../components/banner/Banner';
import rig from '../assets/svg/ArrowDropDownFilled.svg';
import phone from '../assets/svg/ArrowDropDownFilled.svg';
import question from '../assets/svg/ArrowDropDownFilled.svg';
import { useNavigate } from 'react-router-dom';
import Container from '../components/container/Container';
import { Button } from '../components/ui/button';
import { LucideMailQuestion, Phone, PhoneCall } from 'lucide-react';
const ContactUs = () => {
  const navigate = useNavigate();
  const data = {
    page: 'helpline',
    title: 'Contact Us',
    desc: "Want to get in touch? We'd love to hear from you. Here's how you can reach us.",
  };
  const officeAddress = [
    {
      id: 1,
      name: 'City Civil Court, Calcutta',
      address:
        '2 & 3, Kiran Shankar Ray Rd, B.B.D Bagh, Kolkata, West Bengal 700001',
    },
    {
      id: 2,
      name: 'City Civil Court, Calcutta',
      address:
        '3 & 3, Kiran Shankar Ray Rd, B.B.D Bagh, Kolkata, West Bengal 700001',
    },
    {
      id: 3,
      name: 'City Civil Court, Calcutta',
      address:
        '2 & 3, Kiran Shankar Ray Rd, B.B.D Bagh, Kolkata, West Bengal 700001',
    },
  ];
  const [mapState, setMapState] = useState(1);

  const handleExploreFaq = () => {
    navigate('/faq');
  };
  return (
    <>
      <PageDescriptions variant="primary" data={data} />
      <Container>
        <div className="w-full flex flex-col self-stretch justify-between items-start gap-10 lg:flex-row">
          <div className="w-full bg-card-background dark:bg-foreground border-2 border-border lg:w-1/2 py-12 flex flex-row justify-center items-center rounded-xl ">
            <div className=" w-72 h-72 flex flex-col justify-center items-center gap-2.5 ">
              <LucideMailQuestion className="w-12 h-12 text-card-foreground" />
              <div className=" text-card-foreground dark:text-background text-xl font-medium font-inter">
                Have a question
              </div>
              <div className=" w-full px-5 text-center  text-card-foreground  dark:text-background text-sm font-normal font-inter leading-relaxed tracking-wide">
                We suggest you first go through our Frequently Asked Questions
                to find your answer
              </div>
              <Button
                variant="default"
                onClick={handleExploreFaq}
                className="flex flex-row gap-2 py-6 px-8 mt-8 dark:bg-background dark:text-foreground"
              >
                <div className="   text-sm font-semibold font-inter uppercase leading-5 tracking-wide">
                  Explore FAQ
                </div>
              </Button>
            </div>
          </div>
          <div className="w-full lg:w-1/2 bg-card-background dark:bg-foreground border border-border py-12 flex flex-row justify-center items-center  rounded-xl ">
            <div className=" w-72 h-72 flex flex-col justify-center items-center gap-2.5 ">
              <PhoneCall className="w-12 h-12 text-card-foreground" />
              <div className=" text-card-foreground mb-2 text-xl font-medium dark:text-background font-inter">
                Phone / Fax
              </div>
              <div className="text-center px-5 text-card-foreground text-md font-normal dark:text-background font-inter leading-loose">
                landline: 033-2464-8856
              </div>
              <div className="text-center px-5 text-card-foreground text-md font-normal dark:text-background font-inter leading-loose">
                landline: 033-2464-8856
              </div>
              <div className="text-center px-5 text-card-foreground text-md font-normal dark:text-background font-inter leading-loose">
                landline: 033-2464-8856
              </div>
              <Button
                variant="default"
                onClick={handleExploreFaq}
                className="flex flex-row gap-2 py-6 px-8 mt-8 dark:bg-background dark:text-foreground"
              >
                <div className="   text-sm font-semibold font-inter uppercase leading-5 tracking-wide">
                  Call Us
                </div>
              </Button>
            </div>
          </div>
        </div>
      </Container>

      <Container>
        <div className="w-full h-auto z-10 rounded-[15px]  flex flex-col justify-between items-center  gap-10 lg:flex-row">
          <div className=" w-full lg:w-1/2 h-[410px] justify-center items-center flex flex-col">
            {mapState === 1 && (
              <div className="  flex flex-col w-full h-full justify-center rounded-md items-center gap-2.5">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d373.02898707735443!2d88.34393982301016!3d22.569659870540242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0277a1fe051b61%3A0x17a9feefb78f65aa!2sCity%20Civil%20Court%2C%20Calcutta!5e0!3m2!1sen!2sin!4v1715679935562!5m2!1sen!2sin"
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '20px',
                  }}
                  allowFullScreen=""
                  alt="map-1"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="map-1"
                ></iframe>
              </div>
            )}
            {mapState === 2 ? (
              <div className="  flex flex-col w-full h-full justify-center items-center gap-2.5">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3928.682311869921!2d76.31978448885502!3d10.043052500000009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080c377917e985%3A0xb0fd4b1e85a6e51f!2sCochin%20University%20of%20Science%20and%20Technology!5e0!3m2!1sen!2sin!4v1715435460270!5m2!1sen!2sin"
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '20px',
                  }}
                  allowFullScreen=""
                  referrerpolicy="no-referrer-when-downgrade"
                  alt="map-2"
                  title="map-2"
                ></iframe>
              </div>
            ) : (
              ''
            )}
            {mapState === 3 && (
              <div className="  flex flex-col w-full h-full justify-center items-center gap-2.5">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3682.0060249843013!2d88.44250440933989!3d22.65356397934831!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89faa59df6903%3A0xdb1444043648b83!2sNetaji%20Subhash%20Chandra%20Bose%20International%20Airport!5e0!3m2!1sen!2sin!4v1715435535858!5m2!1sen!2sin"
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '20px',
                  }}
                  allowFullScreen=""
                  referrerpolicy="no-referrer-when-downgrade"
                  alt="map"
                  title="map-3"
                ></iframe>
              </div>
            )}
          </div>

          <div className="w-full lg:w-1/2 h-[100%] rounded-[20px] justify-start items-start gap-4 flex flex-col">
            <div className="text-[27px] font-medium font-inter leading-[35.10px]">
              Visit Your Nearest Office
            </div>
            {officeAddress.map((office) => (
              <div
                key={office.id}
                onClick={() => setMapState(office.id)}
                className={`w-[100%] bg-card-background text-card-foreground border border-border cursor-pointer h-auto p-4 rounded-[10px] ${
                  mapState === office.id ? 'border-2 border-primary' : ''
                }`}
              >
                <div className="text-xs font-bold font-inter leading-[14.40px]">
                  {office.name}
                </div>
                <div className="w-auto">
                  <span className="text-[13px] font-bold font-inter leading-normal">
                    Address
                  </span>
                  <span className="text-xs font-normal font-inter leading-snug">
                    : {office.address}
                  </span>
                </div>
                <Button
                  className={mapState === office.id ? 'mt-2 px-10 ' : 'mt-2'}
                  variant={mapState === office.id ? 'default' : 'outline'} // Change variant based on state
                  disabled={mapState === office.id}
                >
                  {mapState === office.id ? 'Active' : 'SEE ON MAP'}
                </Button>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </>
  );
};

export default ContactUs;
