import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from '../components/ui/table'; // Ensure the path is correct

const CustomTable = ({ data }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Set isLoaded to true after the component mounts
    setIsLoaded(false);
    const timeout = setTimeout(() => setIsLoaded(true), 0);
    return () => clearTimeout(timeout);
  }, [data]);

  return (
    <div className="overflow-x-auto">
      <style>
        {`
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          .fade-in {
            animation: fadeIn 0.6s ease-in-out;
          }
        `}
      </style>
      <Table className="min-w-full">
        <TableHeader>
          <TableRow>
            <TableHead>Title</TableHead>
            <TableHead>Description</TableHead>
            <TableHead>Date</TableHead>
            <TableHead>Download</TableHead>
            <TableHead>Pinned</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.length === 0 ? (
            <TableRow>
              <TableCell colSpan="5" className="text-center p-4">
                No Circular's & orders yet, please check again later
              </TableCell>
            </TableRow>
          ) : (
            data.map((item, index) => (
              <TableRow
                key={`${item.Title}-${index}-${isLoaded}`} // Unique key to force re-render
                className={isLoaded ? 'fade-in' : ''}
              >
                <TableCell className="text-[10px] md:text-sm p-1 md:p-2">
                  {item.Title}
                </TableCell>
                <TableCell className="text-[10px] md:text-sm p-1 md:p-2">
                  {item.Description}
                </TableCell>
                <TableCell className="text-[10px] md:text-sm p-1 md:p-2">
                  {item.Date}
                </TableCell>
                <TableCell className="text-[10px] md:text-sm p-1 md:p-2">
                  <a href={item.Download}>Download</a>
                </TableCell>
                <TableCell className="text-[10px] md:text-sm p-1 md:p-2">
                  {item.pinned ? '📌' : ''}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default CustomTable;
