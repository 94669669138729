import React from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../ui/table"

const SimpleTable = (props) => {
    const data = props.data
    const keys = Object.keys(data[0]);
    return (
        <Table>
            {/* <TableCaption>A list of your recent invoices.</TableCaption> */}
            <TableHeader>

                <TableRow>
                    {keys.map((key, index) => (
                        <TableHead className="uppercase font-bold text-xs tracking-wider ">{key}</TableHead>
                        // <th key={index} className="px-2.5 py-1.5 bg-slate-900 text-white opacity-90 text-[10px] font-bold font-inter uppercase leading-[14px] tracking-wide">{key}</th>
                    ))}
                </TableRow>
            </TableHeader>
            <TableBody>

                {data.map((item, index) => (
                    <TableRow>
                        <TableCell className="font-medium">{item.sl_no}</TableCell>
                        <TableCell className="font-medium">{item.name}</TableCell>
                        <TableCell className="font-medium">{item.title}</TableCell>
                        {item.icon && <TableCell className="font-medium">Download {item.icon}</TableCell>}
                    </TableRow>
                ))}
            </TableBody>
        </Table>

    )
}

export default SimpleTable