import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './button';

const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="language-switcher">
      <Button
        onClick={() => changeLanguage('en')}
        className={i18n.language === 'en' ? 'active' : ''}
      >
        {t('En')}
      </Button>

      <Button
        onClick={() => changeLanguage('bn')}
        className={i18n.language === 'bn' ? 'active' : ''}
      >
        {t('বাং')}
      </Button>
    </div>
  );
};

export default LanguageSwitcher;
