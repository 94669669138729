
// Import language files
import enTranslation from './locales/en.json';
import hiTranslation from './locales/hi.json';
import bnTranslation from './locales/bn.json';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next'; // Named import
import LanguageDetector from 'i18next-browser-languagedetector';
const LocalStorageBackend = require('i18next-localstorage-backend').default; // Use require for default import


i18next
  .use(LanguageDetector) // Automatically detect the user's language
  .use(LocalStorageBackend) // Persist language selection in local storage
  .use(initReactI18next) // Pass i18n instance to react-i18next
  .init({
    resources: {
      en: { translation: enTranslation },
      hi: { translation: hiTranslation },
      bn: { translation: bnTranslation }
    },
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false // React already escapes values
    },
    detection: {
      order: ['localStorage', 'cookie', 'navigator'],
      caches: ['localStorage'],
    },
    backend: {
      expirationTime: 7 * 24 * 60 * 60 * 1000 // 7 days
    }
  });

export default i18next;
