import React from 'react';
import { useTheme } from './ThemeContext';
import { Switch } from '../ui/switch';

const ThemeToggle = () => {
  const { toggleTheme } = useTheme();

  return (
    <>
      {/* <button
      onClick={toggleTheme}
      className="px-4 py-2 bg-primary text-primary-foreground rounded"
    >
      Switch to {theme === 'dark' ? 'Light' : 'Dark'} Mode
    </button> */}
      <Switch aria-label="Toggle Theme" onClick={toggleTheme} />
    </>
  );
};

export default ThemeToggle;
