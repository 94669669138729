import React from 'react';
import { cn } from '../../lib/utils';
import { cva } from 'class-variance-authority';

const buttonVariants = cva('px-4 lg:px-20 py-20 lg:py-20 border-b', {
  variants: {
    variant: {
      default: 'bg-primary text-primary-foreground dark:bg-background ',
      primary: 'bg-primary text-primary-foreground dark:bg-background',
    },
  },
});

const Banner = ({ data, variant, className }) => {
  return (
    <>
      <div className={cn(buttonVariants({ variant, className }))}>
        <div className="w-full gap-10 relative flex flex-col justify-center items-center text-center pt-20 pb-5">
          <div className="bg-gradient-to-r py-3 px-10 from-blue-700 to-indigo-300 border-l-2 border-sky-900 justify-center items-center inline-flex">
            <div className="text-foreground text-xs font-semibold font-inter uppercase leading-none tracking-wide ">
              {data.title}
            </div>
          </div>
          <div className="flex flex-col justify-center items-center gap-8">
            <h1 className="text-6xl font-semibold tracking-tight text-primary-foreground dark:text-foreground">
              {data.title}
            </h1>
            <p className="text-normal leading-relaxed text-on-card/90 max-w-xl text-primary-foreground dark:text-foreground">
              {data.desc}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
