import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Container from '../container/Container';

const SkeletonMembershipOverview = () => {
  return (
    <Container className="bg-background py-40 md:py-60">
      <div className="w-full flex-col justify-start md:justify-center gap-9 py-10 inline-flex">
        {/* Skeleton Badge */}
        <div className="w-60 mb-10 mx-auto rounded-sm relative bg-gradient-to-r p-4 from-blue-700 to-indigo-300 inline-flex">
          <div className="absolute inset-0 bg-background m-[2px] content-center justify-center w-[98%] rounded-sm">
            <Skeleton width="100%" height="20px" />
          </div>
        </div>

        {/* Skeleton Heading */}
        <div className="self-stretch text-start md:text-center">
          <Skeleton width="60%" height="40px" className="mx-auto" />
        </div>
      </div>

      {/* Skeleton Cards */}
      <div className="flex flex-col px-2 md:px-0 md:flex-row gap-12">
        {[1, 2, 3].map((_, index) => (
          <div
            key={index}
            className="group bg-secondary rounded-3xl overflow-clip border-1"
          >
            {/* Skeleton Image */}
            <Skeleton height="288px" className="w-full h-72 object-cover" />
            <div className="flex bg-secondary border-t flex-col h-56 px-4 justify-between group-hover:-translate-y-12 group-hover:duration-500">
              <div className="flex flex-col gap-1">
                <Skeleton width="80%" height="20px" />
                <Skeleton width="90%" height="16px" />
              </div>
              <Skeleton width="60%" height="20px" />
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default SkeletonMembershipOverview;
