import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonBenefitsSection = () => {
  const items = Array.from({ length: 6 });

  return (
    <div className="container mx-auto px-4 my-20 py-20">
      <div className="flex flex-col items-center justify-center w-full">
        <div className="w-full flex flex-col gap-2 justify-center items-center">
          <div className="flex-col justify-center items-center gap-3 inline-flex w-full">
            {/* Skeleton Badge */}
            <div className="w-60 mb-10 mx-auto rounded-sm relative bg-gradient-to-r p-4 from-blue-700 to-indigo-300 inline-flex">
              <div className="absolute inset-0 bg-background m-[2px] content-center justify-center w-[98%] rounded-sm">
                <Skeleton width="100%" height="20px" />
              </div>
            </div>
            {/* Skeleton Heading */}
            <Skeleton width="60%" height="32px" className="mx-auto" />
          </div>
          {/* Skeleton Grid */}
          <div className="pt-20 grid grid-cols-1 lg:grid-cols-3 lg:gap-32 justify-center gap-10 w-full">
            {items.map((_, index) => (
              <div
                key={index}
                className="flex flex-col items-center gap-2 max-w-sm"
              >
                <Skeleton circle width="40px" height="40px" />
                <Skeleton width="70%" height="24px" />
                <Skeleton width="80%" height="16px" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonBenefitsSection;
