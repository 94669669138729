import { cn } from '../../lib/utils';
import React from 'react';

const Container = ({ children, className }) => {
  return (
    <div
      className={cn(
        'py-20 w-full border-b px-4 lg:px-16 md:pt-30 pb-40 2xl:px-56 overflow:hidden',
        className
      )}
    >
      {children}
    </div>
  );
};

export default Container;
