import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import About from './pages/About.js';
import Footer from './components/footer/Footer';
import HowItWorks from './pages/HowItWorks';
import './i18next';
import NewNavBar from './components/nav/NewNavBar';
import Home from './pages/Home';
import Faq from './pages/Faq';
import CircularsOrders from './pages/CircularsOrders';
import Forms from './pages/Forms';
import ContactUs from './pages/ContactUs';
import AboutOrg from './pages/AboutOrg';

function App() {
  return (
    // Navigation router setUp to navigate different pages
    <Router>
      <div className="relative">
        <NewNavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="faq" element={<Faq />} />
          <Route path="keyforms" element={<Forms />} />
          <Route path="/circulars-orders" element={<CircularsOrders />} />{' '}
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/about-org" element={<AboutOrg />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
