import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Container from '../container/Container';

const SkeletonFAQSection = () => {
  return (
    <Container>
      <div className="w-full flex flex-col gap-10 py-8 items-center">
        {/* Skeleton Badge */}
        <div className="w-60 rounded-sm relative bg-gradient-to-r p-4 from-blue-700 to-indigo-300 inline-flex">
          <div className="absolute inset-0 bg-secondary m-[2px] w-[98%] rounded-sm">
            <Skeleton width="100%" height="20px" />
          </div>
        </div>

        {/* Skeleton Heading */}
        <Skeleton width="40%" height="32px" />

        {/* Skeleton Subheading */}
        <Skeleton width="60%" height="20px" />

        {/* Skeleton Questions */}
        <div className="flex flex-col gap-4 w-full lg:w-2/3 py-12">
          <Skeleton height="50px" count={5} style={{ marginBottom: '12px' }} />
        </div>

        {/* Skeleton Button */}
        <Skeleton width="200px" height="40px" />
      </div>
    </Container>
  );
};

export default SkeletonFAQSection;
