import React, { useState, useEffect } from 'react';
import CustomTable from '../components/CustomTable';
import Pagination from '../components/Pagination';
import usePagination from '../components/hooks/usePagination'; // Ensure this path is correct
import Banner from '../components/banner/Banner';

const Circulars_Orders = () => {
  const [data, setData] = useState([]);

  const bannerData = {
    page: 'AWF UPDATES',
    title: 'Circulars & Orders',
    desc: 'Explore membership options with the Advocate’s welfare fund. Especially designed for lawyers, this is the cheapest way to keep your family secure.',
  };

  useEffect(() => {
    // Simulate fetching data
    const fetchData = [
      // {
      //   Title: 'Document 1',
      //   Description: 'Description 1',
      //   Date: '2021-01-01',
      //   Download: 'Link 1',
      //   pinned: true,
      // },
    ];
    const sortedData = fetchData.sort((a, b) => {
      if (a.pinned === b.pinned) {
        return new Date(b.Date) - new Date(a.Date); // Sort by date if 'pinned' status is the same
      }
      return a.pinned ? -1 : 1; // Ensure pinned items come first
    });
    console.log('Sorted data:', sortedData);
    setData(sortedData);
  }, []);

  const { currentData, currentPage, setCurrentPage } = usePagination(data, 10);

  return (
    <div>
      <Banner variant="primary" data={bannerData} />
      <div className="container mx-auto py-10 mt-12 relative">
        <div className="relative min-h-[700px] md:min-h-[500px]">
          <CustomTable data={currentData} />
          {/* <Pagination
            total={data.length}
            itemsPerPage={10}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Circulars_Orders;
