import React, { useEffect, useRef } from 'react';
import download from '../../assets/svg/ArrowDropDownFilled.svg';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../components/ui/table';
import { Link } from 'react-router-dom';
import { Download } from 'lucide-react';

const CircularsOrdersTableCn = (props) => {
  const data = props.data;
  const rowsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          } else {
            entry.target.classList.remove('visible');
          }
        });
      },
      { root: null, rootMargin: '-100px', threshold: 0.5 } // Adjust if needed to trigger earlier or later
    );

    const currentRows = rowsRef.current;
    currentRows.forEach((row) => {
      if (row) observer.observe(row);
    });

    return () => {
      currentRows.forEach((row) => {
        if (row) observer.unobserve(row);
      });
      observer.disconnect();
    };
  }, [data]);

  return (
    <>
      <style>
        {`
          .fade-in-up {
            opacity: 0;
            transform: translateY(20px);
            transition: opacity 0.5s ease-out, transform 0.5s ease-out;
          }
          .fade-in-up.visible {
            opacity: 1;
            transform: translateY(0);
          }

        `}
      </style>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="uppercase font-bold text-[11px] tracking-widest">
              Title
            </TableHead>
            <TableHead className="uppercase font-bold text-[11px] tracking-widest">
              Description
            </TableHead>
            <TableHead className="uppercase font-bold text-[11px] tracking-widest">
              Download
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((item, index) => (
            <TableRow
              ref={(el) => (rowsRef.current[index] = el)}
              key={index}
              className="fade-in-up"
            >
              <TableCell className="font-medium">
                {item.Title}
                {item?.tag && (
                  <div className="w-12 h-5 p-1 ml-1 bg-blue-700 rounded-2xl justify-center items-center inline-flex">
                    <div className="text-white text-xs font-semibold font-inter uppercase tracking-wide">
                      {item?.tag}
                    </div>
                  </div>
                )}
              </TableCell>
              <TableCell className="font-medium">{item.Description}</TableCell>
              <TableCell className="font-medium">
                <a
                  href={item.Download}
                  target="_blank"
                  rel="noreferrer"
                  className="flex flex-row gap-1 items-center"
                >
                  <Download className="w-4 h-4 mr-2" />
                  Download
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default CircularsOrdersTableCn;
