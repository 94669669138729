import React, { Suspense, lazy} from 'react';
// Import the skeleton components
import SkeletonMembershipOverviewSection from '../components/Skeletons/SkeletonMemberShipOverView';
import SkeletonBenefitsSection from '../components/Skeletons/SkeletonBenefit';
import SkeletonAboutSection from '../components/Skeletons/SkeletonAbout';
import SkeletonOfficialsSection from '../components/Skeletons/SkeletonOffcial';
import SkeletonFAQSection from '../components/Skeletons/SkeletonFaq';
import SkeletonVidHero from '../components/Skeletons/SkeletonVidHero';
import membership from '../assets/home/cards/card-fillApplication.webp';
import claims from '../assets/home/cards/card-claimBenefits-2.webp';
import renew from '../assets/home/cards/card-payAnnually.webp';

// Lazy load the sections
const MembershipOverviewSection = lazy(() =>
  import('../components/sections/home/MembershipOverView')
);
const BenefitsSection = lazy(() =>
  import('../components/sections/home/BenefitsSection')
);
const AboutSection = lazy(() =>
  import('../components/sections/home/AboutSection')
);
const OfficialsSection = lazy(() =>
  import('../components/sections/home/officialsSection')
);
const FAQSection = lazy(() =>
  import('../components/sections/home/FAQSection')
);
const VidHero = lazy(() => import('../components/sections/home/VidHero'));


const Home = () => {
  

  const cardContent = [
    {
      id: 1,
      image: renew,
      title: 'home.section2.cards.0.title',
      description: 'home.section2.cards.0.description',
      link: '/how-it-works',
    },
    {
      id: 2,
      image: claims,
      title: 'home.section2.cards.1.title',
      description: 'home.section2.cards.1.description',
      link: '/how-it-works',
    },
    {
      id: 3,
      image: membership,
      title: 'home.section2.cards.2.title',
      description: 'home.section2.cards.2.description',
      link: '/how-it-works',
    },
  ];

  const AllQans = [
    {
      id: 1,
      question: 'What is the West Bengal Advocates Welfare Fund?',
      ans: 'The West Bengal Advocates Welfare Fund is a statutory fund established to provide financial benefits to advocates in West Bengal, ensuring support during retirement, cessation of practice, or other significant life events.',
    },
    {
      id: 2,
      question: 'Who is eligible to become a member of the Fund?',
      ans: 'The West Bengal Advocates Welfare Fund is a statutory fund established to provide financial benefits to advocates in West Bengal, ensuring support during retirement, cessation of practice, or other significant life events.',
    },
    {
      id: 3,
      question: 'What are the benefits of joining the Fund?',
      ans: 'The West Bengal Advocates Welfare Fund is a statutory fund established to provide financial benefits to advocates in West Bengal, ensuring support during retirement, cessation of practice, or other significant life events.',
    },
  ];

  return (
    
      <div>
        {/* <Carousel images={images} /> */}
        <Suspense fallback={<SkeletonVidHero />}>
          <VidHero  />
        </Suspense>

        {/* <HomeCaoursel images={images} /> */}
        <Suspense fallback={<SkeletonMembershipOverviewSection />}>
          <MembershipOverviewSection content={cardContent} />
        </Suspense>

        <Suspense fallback={<SkeletonBenefitsSection />}>
          <BenefitsSection />
        </Suspense>

        <Suspense fallback={<SkeletonAboutSection />}>
          <AboutSection />
        </Suspense>

        <Suspense fallback={<SkeletonOfficialsSection />}>
          <OfficialsSection />
        </Suspense>

        <Suspense fallback={<SkeletonFAQSection />}>
          <FAQSection data={AllQans} />
        </Suspense>
      </div>
    
  );
};

export default Home;
