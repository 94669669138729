import React, { useState } from 'react';
import PageDescriptions from '../components/banner/Banner';

import Container from '../components/container/Container';
import {
  CheckCircle,
  CheckCircle2Icon,
  LucideIndianRupee,
  UserPlus2,
} from 'lucide-react';

const HowItWorks = () => {
  const qns1 = [
    {
      id: 1,
      question: 'Step 1: Verify Eligibility',
      ans: 'Ensure that you are a practicing advocate registered with the West Bengal State Bar Council.',
    },
    {
      id: 2,
      question: 'Step 2: Download and fill Membership Form (C)',
      ans: 'Download the Membership Form (C)',
      midBtn: 'https://dev.d3v3iccr5ig8ob.amplifyapp.com/keyforms',
      ans2: 'fill it out accurately to provide all required personal and professional details.',
    },
    {
      id: 3,
      question: 'Step 3: Download and fill Nominee Form (D)',
      ans: 'Complete the Nominee Form (download ',
      midBtn: 'https://dev.d3v3iccr5ig8ob.amplifyapp.com/keyforms',
      ans2: ' )to designate a beneficiary who will receive benefits under the fund in the event of your death.',
    },
    {
      id: 4,
      question: 'Step 4: Gather all required documents',
      ans: 'Collect all necessary documents pertaining to your membership. Ensure all documents are complete and accurate to prevent delays. You can download the list of documents ',
      lastBtn: 'https://dev.d3v3iccr5ig8ob.amplifyapp.com/keyforms',
    },
    {
      id: 5,
      question: 'Step 5: Calculate and prepare your fee amount',
      ans: 'The application fee varies by years of practice: less than five years is ₹50, between five to fifteen years is ₹100, and more than fifteen years is ₹200. Half rates apply for SC/ST applicants.',
    },
    {
      id: 6,
      question: 'Step 6: Submit application',
      ans: 'Submit the application form along with all required documents and the payment receipt to the Fund’s office, either in person or by registered mail. Find your nearest office.',
      lastBtn: 'https://dev.d3v3iccr5ig8ob.amplifyapp.com/keyforms',
    },
    {
      id: 7,
      question: 'Step 7: Receive Confirmation',
      ans: 'Await confirmation of your membership status. This may take a few weeks as the application is processed. ',
    },
  ];
  // Premium Payment question
  const qns2 = [
    {
      id: 1,
      question: 'Step 1: Note the Annual Contribution Payment Date (Jan 1st)',
      ans: 'Members are expected to  annually pay their contributions, typically by January 1st each year.',
    },
    {
      id: 2,
      question: 'Step 2: Calculate Your Contribution',
      ans: 'Contributions may vary based on years of practice and possibly other factors. Check the latest rates here.',
      lastBtn: 'https://dev.d3v3iccr5ig8ob.amplifyapp.com/keyforms',
    },
    {
      id: 3,
      question: 'Step 3: Make the Payment',
      ans: 'Use preferred payment methods such as online banking, cheque, or direct bank deposit to the Fund’s account.',
    },
    {
      id: 4,
      question: 'Step 4: Obtain and Keep the Receipt',
      ans: 'Always obtain a receipt for your payment, whether it is a digital confirmation or a physical receipt, and keep it for your records.',
    },
    {
      id: 5,
      question: 'Step 5: Confirm Payment with the Fund',
      ans: 'It may be a good practice to confirm that your payment has been received and recorded by the Fund’s office to avoid any discrepancies.',
    },
  ];

  // Claim Process question
  const qns3 = [
    {
      id: 1,
      question: 'Step 1: Notification',
      ans: 'Notify the Fund of your intent to claim as soon as the claim condition occurs (e.g., diagnosis of a health condition, decision to retire, or member’s death). ',
    },
    {
      id: 2,
      question: 'Step 2: Download and fill Claim Form (E)',
      ans: 'Download the Claim form',
      midBtn: 'https://dev.d3v3iccr5ig8ob.amplifyapp.com/keyforms',
      ans2: ', and fill it out accurately to provide all required claim-related details',
    },
    {
      id: 3,
      question: 'Step 3: Gather all required documents',
      ans: 'Collect all necessary documents pertinent to your claim type. Ensure all documents are complete and accurate to prevent delays.',
    },
    {
      id: 4,
      question: 'Step 4: Submit your claim ',
      ans: 'Submit the completed claim form along with all supporting documents to the Fund’s office. This can typically be done in person or by mail. Find your nearest nodal office here.',
      lastBtn: 'https://dev.d3v3iccr5ig8ob.amplifyapp.com/keyforms',
    },
    {
      id: 5,
      question: 'Step 5: Claim Review',
      ans: 'Once your claim is submitted, it will be reviewed by the Fund’s claim department. This process includes verification of documents and may require additional information or clarification, so be responsive to any inquiries from the Fund.',
    },
    {
      id: 6,
      question: 'Step 6: Claim Decision',
      ans: 'After review, the Fund will make a decision on your claim. If approved, the benefit amount will be determined based on the Fund’s rules and your specific circumstances.',
    },
    {
      id: 7,
      question: 'Step 7: Disbursement',
      ans: 'If the claim is approved, benefits are typically disbursed directly to the member’s or nominee’s bank account. Ensure the Fund has your correct banking details.',
    },
    {
      id: 8,
      question: 'Step 8: Follow-Up',
      ans: 'If your claim is denied or if you have disputes regarding the claim decision, you may follow the Fund’s appeals process. The details of this process should be provided along with the claim decision notification.',
    },
  ];

  const data = {
    page: 'AWF UPDATES',
    title: 'How It Works',
    desc: 'Explore the processes of membership registration, premium payments, and claims with our straightforward guides.',
  };

  const [currentQuestionSet, setCurrentQuestionSet] = useState(qns1);
  const [activeBtn, setActiveBtn] = useState('New Members');
  const handleClick = (e) => {
    setActiveBtn(e);
    if (e === 'New Members') setCurrentQuestionSet(qns1);
    else if (e === 'Premium Payment') {
      setCurrentQuestionSet(qns2);
    } else if (e === 'Claim Process') setCurrentQuestionSet(qns3);
  };

  return (
    <div>
      <PageDescriptions variant="primary" data={data} />

      {/* Tab container */}
      <div className="w-full pt-8 border-b border-gray-200">
        <div className="flex justify-center gap-8">
          {['New Members', 'Premium Payment', 'Claim Process'].map((btn) => (
            <button
              key={btn}
              className={`py-2 px-6 text-md font-medium 
                ${
                  activeBtn === btn
                    ? 'border-b-2 border-lightActive text-lightActive dark:border-darkActive dark:text-darkActive'
                    : 'border-b-2 border-transparent'
                } 
                hover:bg-gray-100 dark:hover:bg-darkHover hover:shadow-custom-light dark:hover:shadow-custom-dark hover:rounded-md`}
              onClick={() => handleClick(btn)}
            >
              {btn}
            </button>
          ))}
        </div>
      </div>

      {/* accordion container */}
      <div className=" w-[100%] px-12 h-[auto]  py-8 gap-16 max-lg:px-4  max-lg:flex-col max-lg:h-auto max-lg:justify-start flex flex-row justify-between items-start">
        <div className=" w-[70%] max-lg:w-[100%]  flex flex-col h-auto gap-0 ">
          <Container>
            <div className="w-full h-auto min-h-[450px] overflow-y-auto">
              <div className="flex flex-row justify-start items-center mb-5 gap-2">
                <div>
                  {activeBtn === 'New Members' && (
                    <UserPlus2 className="w-8 h-8" />
                  )}
                  {activeBtn === 'Annual subscription' && (
                    <LucideIndianRupee className="w-8 h-8" />
                  )}
                  {activeBtn === 'Claim Process' && (
                    <CheckCircle className="w-8 h-8" />
                  )}
                </div>
                <div className="text-2xl font-medium font-inter">
                  {activeBtn}
                </div>
              </div>
              <div className="w-full">
                {currentQuestionSet.map((question, index) => (
                  <div key={index} className="mt-10 mb-20">
                    <h3 className="text-xl font-semibold mb-2">
                      {question.question}
                    </h3>
                    <p className="text-base mb-2">{question.ans}</p>
                    {question.midBtn && (
                      <p className="text-base mb-2">
                        <a
                          className="font-bold underline"
                          href={question.midBtn}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          here
                        </a>{' '}
                        {question.ans2}
                      </p>
                    )}
                    {question.lastBtn && (
                      <p className="text-base mb-2">
                        <a
                          className="font-bold underline"
                          href={question.lastBtn}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          here
                        </a>
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Container>
        </div>

        {/* Application Requirements Section */}
        <div className="lg:w-2/5 w-full flex flex-col sticky top-0 h-screen overflow-auto ">
          {/* application requirements container */}
          {activeBtn === 'New Members' && (
            <div className="flex-1 lg:pt-32">
              <div className="w-full h-auto pb-20 flex flex-col justify-start items-start gap-4">
                <div className="w-full py-3 text-sm font-normal font-inter border-border border-b">
                  Application Requirements
                </div>

                <div className="flex-row justify-start items-start gap-1 flex">
                  <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />{' '}
                  <div className="text-xs font-normal font-inter">
                    Active advocate registered in West Bengal
                  </div>
                </div>
                <div className="w-full py-3 opacity-60 text-[13px] font-normal border-border border-b">
                  Documents Required
                </div>

                <div className="w-full py-3 opacity-60 text-[13px] font-normal">
                  Application Details
                </div>

                <div className="flex-row justify-start items-start gap-1 flex">
                  <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />{' '}
                  <div className="text-[13px] font-normal font-inter leading-[18px]">
                    Bar Council License/Certificate
                  </div>
                </div>
                <div className="flex-row justify-start items-start gap-1 flex">
                  <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />{' '}
                  <div className="text-[13px] font-normal font-inter leading-[18px]">
                    A recent passport-sized photograph
                  </div>
                </div>
                <div className="flex-row justify-start items-start gap-1 flex">
                  <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />{' '}
                  <div className="text-[13px] font-normal font-inter leading-[18px]">
                    SC/ST Certificate
                  </div>
                </div>
                <div className="flex-row justify-start items-start gap-1 flex">
                  <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />{' '}
                  <div className="text-[13px] font-normal font-inter leading-[18px]">
                    Payment receipt
                  </div>
                </div>

                <div className="h-[38px] w-[100%] py-2.5 flex-col justify-end items-center gap-[9px] flex">
                  <div className="w-[100%] opacity-60 text-[13px] font-normal font-inter leading-[18px]">
                    Nominee Details
                  </div>
                </div>
                <div className="flex-row justify-start items-start gap-1 flex">
                  <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />{' '}
                  <div className="text-[13px] font-normal font-inter leading-[18px]">
                    Nominee name, address and contact
                  </div>
                </div>
                <div className="flex-row justify-start items-start gap-1 flex">
                  <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />{' '}
                  <div className="text-[13px] font-normal font-inter leading-[18px]">
                    Relationship to member
                  </div>
                </div>
                <div className="flex-row justify-start items-start gap-1 flex">
                  <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />{' '}
                  <div className="text-[13px] font-normal font-inter leading-[18px]">
                    Nominee KYC
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* 2nd */}
          {activeBtn === 'Premium Payment' && (
            <div className="flex-1 max-lg:w-[100%] lg:pt-32 flex flex-col justify-start items-start">
              <div className="w-[100%] h-[509px] pb-20 flex-col justify-start items-start gap-[15px] inline-flex">
                <div className="h-[38px] w-[100%] pt-2.5 flex-col justify-end items-center gap-[9px] flex">
                  <div className="w-[100%] text-[15px] font-normal font-inter leading-[18px]">
                    Premium Payment Documents
                  </div>
                  <div className="self-stretch h-[1px] border border-b-1 border-border opacity-20 justify-center items-center inline-flex" />
                </div>
                <div className="flex-col justify-start items-start gap-[9px] flex">
                  <div className="w-[100%] opacity-60 text-[13px] font-normal font-inter leading-[18px]">
                    Membership Proof
                  </div>
                  <div className="flex-col justify-start items-start gap-3 flex">
                    <div className="justify-start items-center gap-2.5 inline-flex">
                      <div>
                        <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />{' '}
                      </div>
                      <div className="text-[13px] font-normal font-inter leading-[18px]">
                        Membership ID or Number
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex-col justify-start items-start gap-[9px] flex">
                  <div className="w-[100%] opacity-60 text-[13px] font-normal font-inter leading-[18px]">
                    Payment Methods
                  </div>
                  <div className="flex-col justify-start items-start gap-3 flex">
                    <div className="justify-start items-center gap-2.5 inline-flex">
                      <div>
                        <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />{' '}
                      </div>
                      <div className="text-[13px] font-normal font-inter leading-[18px]">
                        Bank Details/Form
                      </div>
                    </div>
                    <div className="justify-start items-center gap-2.5 inline-flex">
                      <div>
                        <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />{' '}
                      </div>
                      <div className="text-[13px] font-normal font-inter leading-[18px]">
                        Cheque or Bank Transfer Details
                      </div>
                    </div>
                    <div className="justify-start items-center gap-2.5 inline-flex">
                      <div>
                        <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />{' '}
                      </div>
                      <div className="text-[13px] font-normal font-inter leading-[18px]">
                        Online Payment Receipt
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* 3rd */}
          {activeBtn === 'Claim Process' && (
            <div className="flex-1 max-lg:w-[100%] lg:pt-32 pb-40 flex flex-col justify-start items-start">
              <div className="w-[100%] h-[509px] pb-20 flex-col justify-start items-start gap-[15px] inline-flex">
                <div className="h-[38px] w-[100%] pt-2.5 flex-col justify-end items-center gap-[9px] flex">
                  <div className="w-[100%] text-[15px] font-normal font-inter leading-[18px]">
                    Claim Filing Requirements
                  </div>
                  <div className="self-stretch h-[1px] border border-b-1 border-border opacity-20 justify-center items-center inline-flex" />
                </div>
                <div className="flex-col justify-start items-start gap-[9px] flex">
                  <div className="w-[100%] opacity-60 text-[13px] font-normal font-inter leading-[18px]">
                    Basic Documents
                  </div>
                  <div className="flex-col justify-start items-start gap-3 flex">
                    <div className="justify-start items-center gap-2.5 inline-flex">
                      <div>
                        <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />{' '}
                      </div>
                      <div className="text-[13px] font-normal font-inter leading-[18px]">
                        A copy of AWF membership card or certificate
                      </div>
                    </div>
                    <div className="justify-start items-center gap-2.5 inline-flex">
                      <div>
                        <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />{' '}
                      </div>
                      <div className="text-[13px] font-normal font-inter leading-[18px]">
                        Government Issued ID such as Aadhar or PAN
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-[38px] w-[100%] pt-2.5 flex-col justify-end items-center gap-[9px] flex">
                  <div className="w-[100%] text-[15px] font-normal font-inter leading-[18px]">
                    Additional Documents Required
                  </div>
                  <div className="self-stretch h-[.6px] border border-b-1 border-border opacity-20 justify-center items-center inline-flex" />
                </div>
                <div className="flex-col justify-start items-start gap-[9px] flex">
                  <div className="w-[100%] opacity-60 text-[13px] font-normal font-inter leading-[18px]">
                    Health Claims
                  </div>
                  <div className="flex-col justify-start items-start gap-3 flex">
                    <div className="justify-start items-center gap-2.5 inline-flex">
                      <div>
                        <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />{' '}
                      </div>
                      <div className="text-[13px] font-normal font-inter leading-[18px]">
                        Medical Certificate from a recognised practitioner
                      </div>
                    </div>
                    <div className="justify-start items-center gap-2.5 inline-flex">
                      <div>
                        <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />{' '}
                      </div>
                      <div className="text-[13px] font-normal font-inter leading-[18px]">
                        Detailed medical records supporting the claim
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-col justify-start items-start gap-[9px] flex">
                  <div className="w-[100%] opacity-60 text-[13px] font-normal font-inter leading-[18px]">
                    Retirement Claims
                  </div>
                  <div className="flex-col justify-start items-start gap-3 flex">
                    <div className="justify-start items-center gap-2.5 inline-flex">
                      <div>
                        <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />
                      </div>
                      <div className="text-[13px] font-normal font-inter leading-[18px]">
                        Proof of age (e.g., birth certificate or passport)
                      </div>
                    </div>
                    <div className="justify-start items-center gap-2.5 inline-flex">
                      <div>
                        <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />
                      </div>
                      <div className="text-[13px] font-normal font-inter leading-[18px]">
                        Documentation proving cessation of practice
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-col justify-start items-start gap-[9px] flex">
                  <div className="w-[100%] opacity-60 text-[13px] font-normal font-inter leading-[18px]">
                    Death Claims
                  </div>
                  <div className="flex-col justify-start items-start gap-3 flex">
                    <div className="justify-start items-center gap-2.5 inline-flex">
                      <div>
                        <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />
                      </div>
                      <div className="text-[13px] font-normal font-inter leading-[18px]">
                        Proof of age (e.g., birth certificate or passport)
                      </div>
                    </div>
                    <div className="justify-start items-center gap-2.5 inline-flex">
                      <div>
                        <CheckCircle2Icon className="w-6 h-6 text-green-900 dark:text-green-200" />
                      </div>
                      <div className="text-[13px] font-normal font-inter leading-[18px]">
                        Documentation proving cessation of practice
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
