import React from 'react';
import { useTranslation } from 'react-i18next';
import PageDescriptions from '../components/banner/Banner';
import ms1 from '../assets/home/member_secretary1.webp';
import ms2 from '../assets/home/member_secretary2.webp';
import ms3 from '../assets/home/member_secretary3.webp';
import SimpleTable from '../components/table/SimpleTable';
import Container from '../components/container/Container';

const About = () => {
  const { t } = useTranslation();

  const data = t('about.pageDescription', { returnObjects: true });
  const executiveCommittee = t('about.executiveCommittee', {
    returnObjects: true,
  });
  const listOfMembers = t('about.listOfMembers', { returnObjects: true });
  const barCouncilElects = t('about.barCouncilElects', { returnObjects: true });
  const wbElectedMembers = t('about.wbElectedMembers', { returnObjects: true });

  const images = [ms1, ms2, ms3];

  return (
    <>
      <PageDescriptions data={data} variant="default" />

      <Container className="">
        <div className="w-full gap-10 flex flex-col justify-center items-center content-center">
          <div className="text-2xl font-bold font-inter text-card-foreground">
            {executiveCommittee.title}
          </div>
          <div className="flex flex-col gap-20 w-[100%] justify-center lg:flex-row">
            {executiveCommittee.members.map((member, index) => (
              <div
                key={member.id}
                className="flex flex-col p-2 bg-secondary gap-1"
              >
                <div className="mb-2">
                  <img
                    className="w-80 h-80 object-cover overflow-clip"
                    src={images[index]}
                    alt={member.name}
                  />
                </div>
                <div className="text-base font-semibold font-inter leading-snug text-card-foreground">
                  {`${member.position} — ${member.name}`}
                </div>
                <div className="w-80 text-sm font-normal font-inter leading-snug text-card-foreground">
                  {member.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>

      <Container className="border-border border-b py-28">
        <div>
          <div className="pb-12 w-full flex flex-col justify-center content-center items-center">
            <div className="text-xl font-medium font-inter">
              {listOfMembers.title}
            </div>
          </div>
          <SimpleTable data={listOfMembers.members} />
        </div>
      </Container>

      <Container className="border-border border-b py-28">
        <div>
          <div className="w-full flex flex-col pb-12 justify-center content-center items-center">
            <div className="text-xl font-medium font-inter">
              {barCouncilElects.title}
            </div>
          </div>
          <SimpleTable data={barCouncilElects.members} />
        </div>
      </Container>

      <Container className="border-border border-b py-28">
        <div>
          <div className="w-full flex flex-col pb-12 justify-center content-center items-center">
            <h3 className="text-xl font-medium font-inter text-center">
              {wbElectedMembers.title}
            </h3>
          </div>
          <SimpleTable data={wbElectedMembers.members} />
        </div>
      </Container>
    </>
  );
};

export default About;
