import React, { useState } from 'react';
import PageDescriptions from '../components/banner/Banner';
import PersonAddFilleds from '../assets/svg/lockVector.svg';
import rup from '../assets/svg/rupVector.svg';
import FAQuestion from '../components/smallComponents/FAQuestion';
import Container from '../components/container/Container';

const Faq = () => {
  const data = {
    page: 'FAQ',
    title: 'Frequently Asked Questions',
    desc: 'Get clear answers to commonly asked questions about the Advocates Welfare Fund and understand how it benefits you and your family',
  };

  const [activeSection, setActiveSection] = useState(
    'MEMBERSHIPANDELIGIBILITY'
  );

  const MEMBERSHIPANDELIGIBILITY = [
    {
      id: 1,
      question: 'What is the West Bengal Advocates Welfare Fund?',
      ans: 'The West Bengal Advocates Welfare Fund is a statutory fund established to provide financial benefits to advocates in West Bengal, ensuring support during retirement, cessation of practice, or other significant life events.',
    },
    {
      id: 2,
      question: 'Who is eligible to become a member of the Fund?',
      ans: 'Any advocate whose name is entered in the State roll of advocates and who is a member of a recognized Bar Association in West Bengal is eligible to join the Fund.',
    },
    {
      id: 3,
      question: 'How can I apply for membership in the Fund?',
      ans: 'You can apply for membership by submitting a prescribed application form available from the Fund’s office or downloadable from its official website, along with the necessary documentation and fees.',
    },
    {
      id: 4,
      question: 'How is the Fund managed?',
      ans: 'The Fund is managed by a Trust Committee consisting of members appointed from among advocates and officials from the legal and government sectors.',
    },
    {
      id: 5,
      question:
        'Are there any exclusions or conditions where the Act does not apply?',
      ans: 'Yes, the Act does not apply to advocates who are not members of any recognized Bar Association, among other exclusions specified in the Act.',
    },
    {
      id: 6,
      question: 'What are the responsibilities of a member under the Fund?',
      ans: 'Members are responsible for paying annual contributions, updating personal information, and complying with the rules and regulations of the Fund.',
    },
    {
      id: 7,
      question:
        'What are the tiered application fee amounts for joining the Fund?',
      ans: 'The application fee varies by years of practice: less than five years is ₹50, between five to fifteen years is ₹100, and more than fifteen years is ₹200. Half rates apply for SC/ST applicants.',
    },
    {
      id: 8,
      question:
        'What types of documents are required for membership application?',
      ans: "Below are required documents needed for the membership application. Please note that this list may be updated as necessary by AWF. \n\n1. Proof of Enrollment with the State Bar Council: Official document showing the lawyer's registration with the West Bengal State Bar Council.\n2. Proof of Membership in a Recognized Bar Association: Certificate or card that confirms active membership in a recognized Bar Association in West Bengal.\n3. Identity Verification Documents: Could include one or more of the following:\n   - Government-issued photo ID (e.g., Aadhaar card, PAN card)\n   - Passport\n   - Voter ID\n4. Nomination Form (if nominating beneficiaries): Official form used to designate beneficiaries for receiving benefits in the event of the member’s death.\n5. Supporting Documents for Changes in Personal Details (for updating personal details): Relevant documents to support changes such as address, name, or contact information.",
    },
    {
      id: 9,
      question: 'How can members update their personal details with the Fund?',
      ans: "Members need to submit a written application along with supporting documents for any changes in personal details to the Fund's office.",
    },
  ];
  const CONTRIBUTIONSANDPAYMENTS = [
    {
      id: 1,
      question: 'How are the contributions calculated?',
      ans: 'Contributions are based on a tiered system, depending on the number of years of practice. The exact rates are periodically revised and can be confirmed from the Fund’s office. The current rates are below: {INSERT CONTRIBUTION TABLE]',
    },
    {
      id: 2,
      question: 'What happens to my contributions if I cease to practice law?',
      ans: 'If you permanently cease to practice law, you may be eligible to claim benefits depending on the circumstances of cessation. Contributions until cessation contribute towards the calculation of your eligible benefits.',
    },
    {
      id: 3,
      question: 'Can members pay their contributions in installments?',
      ans: 'Yes, members can choose to pay their annual contribution in two equal half-yearly installments.',
    },
    {
      id: 4,
      question:
        'What happens if a member fails to pay the annual subscription on time?',
      ans: 'Members who fail to pay their annual subscription by the due date may face suspension of benefits and need to regularize their membership by paying the overdue subscription along with applicable interest.',
    },
    {
      id: 5,
      question:
        'Is there any other payment, outside my annual contribution, that I need to make?',
      ans: 'No, there are no other payments that a member needs to make outside of the application fee and annual contributions. only',
    },
  ];

  const BENEFITSANDCLAIMS = [
    {
      id: 1,
      question: 'What are the benefits of joining the Fund?',
      ans: 'Members are entitled to financial benefits upon retirement, cessation of practice, or unforeseen life events. The Fund also provides death benefits to the families of deceased members.',
    },
    {
      id: 2,
      question: 'Is there any provision for borrowing from the Fund?',
      ans: 'The Trust Committee may borrow money for the purposes of the Fund under certain conditions as specified in the Act.',
    },
    {
      id: 3,
      question: 'Can I access the benefits anytime?',
      ans: 'Benefits are generally accessible upon meeting specific conditions such as retirement or cessation of practice. Early access in special circumstances must be approved by the Trust Committee according to the rules set forth in the Act.',
    },
    {
      id: 4,
      question: 'What is the process for claiming benefits?',
      ans: 'To claim benefits, members must submit a claim form along with necessary supporting documents. The process and required documents can vary depending on the type of benefit claimed.',
    },
    {
      id: 5,
      question: 'How are disputes regarding claims resolved?',
      ans: 'Disputes related to claims or membership are resolved through an appeals process handled by the Trust Committee. Details of the appeals process are available in the Act.',
    },
    {
      id: 6,
      question:
        'What are the tiered application fee amounts for joining the Fund?',
      ans: 'The application fee varies by years of practice: less than five years is ₹50, between five to fifteen years is ₹100, and more than fifteen years is ₹200. Half rates apply for SC/ST applicants.',
    },
    {
      id: 7,
      question: 'What is the average processing time for a benefit claim?',
      ans: 'Benefit claims are usually processed within 90 days from the date of receipt, subject to the provision of all required documentation and verification.',
    },
    {
      id: 8,
      question:
        'Is there a provision for financial support in case of temporary incapacity of a member?',
      ans: 'The Fund does not typically cover temporary incapacity unless it leads to cessation of practice, in which case the member might be eligible for cessation benefits under certain conditions.',
    },
    {
      id: 9,
      question:
        'What is the procedure for appealing a decision made by the Trust Committee regarding claims?',
      ans: 'Members can appeal decisions by submitting a written appeal to the Trust Committee within 30 days from the date of receiving the decision. The appeal should clearly state the grounds for the appeal and include any supporting evidence.',
    },
  ];

  const NOMINATIONANDFAMILYBENEFITS = [
    {
      id: 10,
      question: 'Can I nominate a beneficiary for my benefits?',
      ans: 'Yes, members can nominate one or more persons to receive the benefits from the Fund in the event of their death.',
    },
    {
      id: 11,
      question:
        'Can I update my personal details and nomination after joining?',
      ans: 'Yes, members can update their personal details and nominations by notifying the Fund office as specified in the Act.',
    },
    {
      id: 12,
      question: 'Are there specific guidelines for nominating a beneficiary?',
      ans: "Yes, members must use the prescribed nomination form available from the Fund's office or website. Multiple beneficiaries can be designated, but their respective shares must be clearly indicated.",
    },
    {
      id: 13,
      question:
        'What are the specific conditions under which family benefits are paid out?',
      ans: 'Family benefits are paid upon the death of a member to the nominated beneficiary. If no beneficiary is nominated, benefits are paid to the legal heirs as determined by relevant laws.',
    },
  ];

  const handleClick = (sectionId) => {
    setActiveSection(sectionId);
    const headerOffset = 100; // Height of your header in pixels
    const elementPosition = document
      .getElementById(sectionId)
      .getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  const btnToMap = [
    {
      id: 1,
      btn: 'MEMBERSHIPANDELIGIBILITY',
      label: 'MEMBERSHIP AND ELIGIBILITY',
      icon: PersonAddFilleds,
    },
    {
      id: 2,
      btn: 'CONTRIBUTIONSANDPAYMENTS',
      label: 'CONTRIBUTIONS AND PAYMENTS',
      icon: rup,
    },
    {
      id: 3,
      btn: 'BENEFITSANDCLAIMS',
      label: 'BENEFITS AND CLAIMS',
      icon: rup,
    },
    {
      id: 4,
      btn: 'NOMINATIONANDFAMILYBENEFITS',
      label: 'NOMINATION AND FAMILY BENEFITS',
      icon: rup,
    },
  ];

  return (
    <>
      <PageDescriptions variant="primary" data={data} />
      <Container>
        <div className="sticky top-10 bg-background z-1000 justify-center text-center py-2">
          <p className="hidden lg:block text-center uppercase text-xs pb-5 tracking-widest text-blue-900 dark:text-blue-300">
            Quick Links
          </p>{' '}
          {/* Label for the buttons */}
          <div className="hidden lg:flex w-[100%] pb-8 h-auto relative justify-center items-center flex-wrap flex-row gap-3">
            <div className="flex border border-border rounded-full p-1">
              {btnToMap.map((btn) => (
                <div
                  key={btn.id}
                  onClick={() => handleClick(btn.btn)}
                  className={`px-4 py-2 cursor-pointer flex items-center justify-center rounded-full ${
                    activeSection === btn.btn ? 'bg-secondary' : ''
                  }`}
                >
                  <div className="w-5 h-5 relative mr-2">
                    <img
                      className="w-full h-full rounded-full object-contain"
                      alt=""
                      src={btn.icon}
                    />
                  </div>
                  <div className="text-center text-xs font-medium">
                    {btn.label}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center content-center w-[100%]">
          <section id="MEMBERSHIPANDELIGIBILITY">
            <h2 className="text-xl font-bold mt-20 mb-4">
              Membership & Eligibility
            </h2>
            {MEMBERSHIPANDELIGIBILITY.map((q) => (
              <FAQuestion key={q.id} data={q} />
            ))}
          </section>
          <section id="CONTRIBUTIONSANDPAYMENTS">
            <h2 className="text-xl font-bold mt-20 mb-4">
              Contributions & Payments
            </h2>
            {CONTRIBUTIONSANDPAYMENTS.map((q) => (
              <FAQuestion key={q.id} data={q} />
            ))}
          </section>
          <section id="BENEFITSANDCLAIMS">
            <h2 className="text-xl font-bold mt-20 mb-4">Benefits & Claims</h2>
            {BENEFITSANDCLAIMS.map((q) => (
              <FAQuestion key={q.id} data={q} />
            ))}
          </section>
          <section id="NOMINATIONANDFAMILYBENEFITS">
            <h2 className="text-xl font-bold mt-20 mb-4">
              Nomination & Family Benefits
            </h2>
            {NOMINATIONANDFAMILYBENEFITS.map((q) => (
              <FAQuestion key={q.id} data={q} />
            ))}
          </section>
        </div>
      </Container>
    </>
  );
};
export default Faq;
