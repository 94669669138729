import React from 'react';
import justice from '../../assets/home/logos/GoI.webp';
const Footer = () => {
  const footerLinks = [
    {
      title: 'ORGANIZATION',
      links: [
        { title: 'Home', href: '#' },
        { title: 'About', href: '#' },
        { title: 'Acts/Rules', href: '#' },
        { title: 'RTI', href: '#' },
      ],
    },
    {
      title: 'FORMS',
      links: [
        { title: 'Circulars/Orders', href: '#' },
        { title: 'Claim Forms', href: '#' },
        { title: 'Registration Forms', href: '#' },
      ],
    },
  ];

  return (
    <footer className="bg-primary-gradient-dark  py-14  text-sm  lg:px-20">
      <div className=" lg:flex-row justify-between flex flex-col p-6  lg:p-0 max-w-[120rem] mx-auto gap-8">
        <div className=" flex flex-row justify-start items-start gap-3 max-lg:flex-col">
          <img alt="" className=" w-24 h-24 object-contain" src={justice} />
          {/*This control the logo container*/}
          <div className="flex-col h-full ">
            <div className=" text-lg font-black text-[20px] mb-5 font-inter  leading-[27px] tracking-[1px]">
              Department of Legal Affairs
            </div>
            <div className="mt-auto flex flex-col text-grey gap-3">
              <div className=" text-lg font-normal font-inter leading-[27px] tracking-tight">
                Ministry of Law & Justice{' '}
              </div>
              <div className=" text-lg font-normal font-inter leading-[27px] tracking-tight">
                West Bengal
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col  justify-between  lg:flex-row max-w-fit gap-16">
          {footerLinks.map((column) => (
            <div
              key={column.title}
              className="mt-10 min-w-[40%]  lg:mt-0 lg:min-w-[8rem] mr-4"
            >
              <div className=" text-[10.70px] mb-4 font-normal font-inter uppercase leading-none tracking-tight">
                {column.title}
              </div>
              {/* <h3 className=" text-[10.70px] font-normal font-inter uppercase leading-none tracking-tight mt-10 min-w-[40%]  lg:mt-0 lg:min-w-[8rem] mr-4">{column.title}</h3> */}
              <ul>
                {column.links.map((link) => (
                  <li key={link.title} className="[&_a]:last:mb-0">
                    <a
                      className="mb-3 block text-grey transition-colors hover:text-off-white"
                      href={link.href}
                    >
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="flex flex-col  justify-between  max-w-fit gap-2 ">
          <div className=" text-[10.70px] font-normal font-inter uppercase leading-none tracking-tight">
            CALL US AT
          </div>
          <div className=" text-[16.70px] font-bold font-inter uppercase leading-[59.55px] tracking-tight">
            +91 (033) 2231-9363
          </div>
          <div className=" text-[9.70px] font-normal font-inter mb-8 uppercase leading-[14.55px] tracking-tight">
            [mon-sat, 9am -6pm]
          </div>
          <div className=" text-[10.70px] font-normal font-inter uppercase leading-none tracking-tight">
            VISIT US AT
          </div>
          <p className="font-bold font-inter capitalize tracking-tight">
            City Civil Court,
            <br />
            2 & 3, Kiran Shankar Ray Rd, B.B.D. Bagh, <br />
            Kolkata, West Bengal 700001
          </p>
        </div>
      </div>
      <div className="w-[100%] h-[0px] border-t"></div>
      <div className=" w-[100%] flex flex-row justify-between gap-40 mt-8 max-lg:flex-col max-lg:gap-5 max-lg:px-[20px] ">
        <div className=" flex w-[40%] max-lg:w-full   text-sm font-normal font-['Inter'] leading-[21px] tracking-tight">
          © 2024. All rights reserved.
          <br />
          <br />
          This site is designed, developed by Omara Technologies for the
          Advocate Welfare Association of West Bengal.
          <br />
          Last reviewed and updated on : 22nd-December-2023
        </div>
        <div className=" flex w-[40%] max-lg:w-full   text-sm font-normal font-['Inter'] leading-[21px] tracking-tight">
          Disclaimer : The information on this website is provided for
          informational purposes only and does not constitute legal or insurance
          advice. We do not guarantee the accuracy, adequacy or the completeness
          of the information contained here.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
