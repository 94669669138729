import React from 'react';
import Container from '../container/Container';
import Skeleton from 'react-loading-skeleton';

const SkeletonAboutSection = () => {
  return (
    <Container className="bg-primary">
      <div className="w-full py-12 flex flex-col-reverse lg:flex-row gap-36">
        {/* Stats Skeleton Section */}
        <div className="w-full lg:w-1/2 flex justify-center items-center">
          <div className="w-full flex flex-col p-8">
            {[1, 2, 3, 4].map((_, index) => (
              <div
                key={index}
                className={`w-full min-h-40 flex ${
                  index + 2 < 4 ? 'border-b' : ''
                } border-border`}
              >
                <div className="w-1/2 flex flex-col gap-2 justify-center md:items-center border-r border-border p-4">
                  <Skeleton width={100} height={30} />
                  <Skeleton width={70} height={20} />
                </div>
                <div className="w-1/2 flex flex-col gap-2 justify-center md:items-center p-8">
                  <Skeleton width={100} height={30} />
                  <Skeleton width={70} height={20} />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Badge and Description Skeleton Section */}
        <div className="w-full lg:w-2/5 flex flex-col gap-4">
          {/* Badge Skeleton */}
          <div className="w-60 mr-auto rounded-sm relative bg-gradient-to-r p-4 from-blue-700 to-indigo-300 inline-flex">
            <div className="absolute inset-0 bg-primary m-[2px] mr-[4px] content-center justify-center w-[98%] rounded-sm">
              <Skeleton height={20} width={120} />
            </div>
          </div>

          {/* Text Skeleton */}
          <div className="flex mt-10 mb-10 flex-col gap-2 text-primary-foreground">
            <Skeleton width="80%" height={30} className="mb-6" />
            <Skeleton width="100%" height={20} className="mb-16" />
            <Skeleton width="100%" height={40} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SkeletonAboutSection;
