import React from 'react';
// import PageDescriptions from '../components/smallComponents/PageDescriptions'
// import CircularsOrdersTable from '../components/table/CircularsOrdersTable'

import PageDescriptions from '../components/banner/Banner';

import CircularsOrdersTableCn from '../components/table/CircularsOrdersTableCn';
import Container from '../components/container/Container';

const Forms = () => {
  const data = {
    page: 'AWF UPDATES',
    title: 'Key Forms',
    desc: 'Explore membership options with the Advocate’s welfare fund. Especially designed for lawyers, this is the cheapest way to keep your family secure.',
  };

  const PinnedcircularsOrders = [
    {
      Title: 'Form C - Membership Form',
      Description: 'Advocate’s welfare Fund membership form',
      Download: '/formC.pdf',
    },
    {
      Title: 'Form D - Nomination Form',
      Description: 'Advocate’s welfare Fund nomination form',
      Download: '/formD.pdf',
    },
    {
      Title: 'Form E - Claim Form',
      Description: 'Advocate’s welfare Fund claim form',
      Download: '/formE.pdf',
    },
  ];

  return (
    <>
      <PageDescriptions variant="primary" data={data} />
      <Container>
        <div className="text-xl font-medium font-inter pt-10 pb-5">Forms</div>
        <CircularsOrdersTableCn data={PinnedcircularsOrders} />
      </Container>
    </>
  );
};

export default Forms;
