import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Container from '../container/Container';

const SkeletonVidHero = () => {
  return (
    <div>
      <div className="relative bg-black text-white flex items-center justify-center py-24 h-[90vh] min-h-[560px] max-h-[900px]">
        <div className="absolute z-20 top-0 inset-0 left-0 w-full h-full bg-black/50" />
        <div className="absolute top-0 inset-0 left-0 w-full h-full z-10 object-cover bg-gray-800" />

        <Container className="z-30 border-b-0 py-16 flex flex-col items-center text-center relative">
          <div className="flex flex-col items-center text-center bg-white/20 p-2 mb-8">
            {/* Badge skeleton */}
            <Skeleton width={100} height={20} className="text-white font-semibold text-sm" />
          </div>

          {/* Heading skeleton */}
          <Skeleton
            width="80%"
            height={40}
            className="mb-7 tracking-tight font-medium leading-normal text-center"
          />

          {/* Subheading skeleton */}
          <Skeleton
            width="60%"
            height={30}
            className="mb-5 leading-relaxed w-11/12 md:w-8/12 xl:w-1/2 mx-auto text-white font-light"
          />

          {/* Button skeleton */}
          <Skeleton width={150} height={40} className="mt-8" />
        </Container>
      </div>

      <style>
        {`
          @keyframes scrollLogos {
            0% { transform: translateX(0); }
            100% { transform: translateX(-33.3333%); }
          }
          .animate-marquee {
            display: flex;
            animation: scrollLogos 30s linear infinite;
            width: 300%;
          }
          @media (max-width: 768px) {
            .animate-marquee img {
              width: auto%;
              max-width: 120px;
              height: auto; 
              margin-left: 20px;
              margin-right: 20px;
            }
            .animate-marquee {
              animation: scrollLogos 65s linear infinite;
              justify-content: space-around;
            }
          }
        `}
      </style>

      {/* Logos skeleton */}
      <div className="relative overflow-hidden w-full">
        <div className="flex whitespace-nowrap animate-marquee">
          {[...Array(6)].map((_, i) => (
            <Skeleton
              key={i}
              width={100}
              height={64}
              className="mx-auto my-auto"
              style={{ margin: '0 20px' }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SkeletonVidHero;
