import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Container from '../container/Container';

const SkeletonOfficialsSection = () => {
  return (
    <Container className="bg-secondary">
      <div className="w-full py-12 flex flex-col gap-8">
        <div className="w-full flex flex-col gap-4">
          {/* Skeleton Badge */}
          <div className="w-60 mr-auto rounded-sm relative bg-gradient-to-r p-4 from-blue-700 to-indigo-300 inline-flex">
            <div className="absolute inset-0 bg-secondary m-[2px] mr-[4px] content-center justify-centers w-[98%] rounded-sm">
              <Skeleton width="100%" height="20px" />
            </div>
          </div>

          {/* Skeleton Heading */}
          <Skeleton width="40%" height="32px" className="mt-4" />

          {/* Skeleton Body */}
          <Skeleton width="60%" height="20px" className="mt-4" />
        </div>

        {/* Skeleton Subheading */}
        <Skeleton width="30%" height="24px" className="mt-6" />

        {/* Skeleton for each official */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-6">
          {[...Array(3)].map((_, index) => (
            <div key={index} className="flex flex-col items-center text-center">
              {/* Circular skeleton for profile image */}
              <Skeleton circle={true} height={200} width={200} />
              {/* Name Skeleton */}
              <Skeleton width="60%" height="20px" className="mt-4" />
              {/* Position Skeleton */}
              <Skeleton width="40%" height="16px" className="mt-2" />
              {/* Description Skeleton */}
              <Skeleton width="80%" height="16px" className="mt-4" />
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default SkeletonOfficialsSection;
