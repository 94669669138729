import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../components/container/Container';
import legalChambers from '../assets/home/chambers.webp';
import conference from '../assets/home/legalConference-2.webp';
import FAQuestion from '../components/smallComponents/FAQuestion';
import download from '../assets/svg/ArrowDropDownFilled.svg';
import { Button } from '../components/ui/button';
import { Card, CardHeader, CardTitle } from '../components/ui/card';

const AboutOrg = () => {
  const { t } = useTranslation();

  const AllQans = t('aboutOrg.faq', { returnObjects: true });
  const cardData = t('aboutOrg.enrollmentSteps', { returnObjects: true });

  return (
    <>
      <Container>
        <div className="flex flex-col pb-10 pt-5 gap-10">
          <div className=" font-semibold text-2xl lg:text-4xl">
            {t('aboutOrg.title')}
          </div>
          <div className=" font-normal text-sm lg:w-[60%]">
            {t('aboutOrg.description')}
          </div>
        </div>
        <div>
          <img
            className=" w-full object-cover"
            src={conference}
            alt="another rendition of chambers"
          />
        </div>
      </Container>
      <Container className=" bg-foreground text-background w-full">
        <div className=" font-normal text-2xl lg:text-4xl mb-20 ">
          {t('aboutOrg.supportStatement')}
        </div>
        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-32">
          <div>
            <img
              className="w-full h-2/3 object-cover"
              src={legalChambers}
              alt="legal chambers with a judge presiding"
            />
          </div>
          <div className="flex flex-col gap-14">
            {t('aboutOrg.timeline', { returnObjects: true }).map(
              (item, index) => (
                <div
                  key={index}
                  className=" flex flex-col gap-4  border-l border-border pl-5 "
                >
                  <div className=" text-xl font-bold tracking-tight leading-normal ">
                    {item.date}: {item.title}
                  </div>
                  <div className="text-sm font-normal font-inter leading-snug ">
                    {item.description}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </Container>

      <Container>
        <div className=" font-semibold text-lg">
          {t('aboutOrg.keyStipulations')}
        </div>
        <FAQuestion data={AllQans} />
        <div className=" w-full flex flex-row justify-center items-center ">
          <Button
            variant="default"
            className=" mt-10 text-card-foreground flex flex-row bg- px-10 gap-5 "
          >
            <img className="text-card-foreground" alt="" src={download} />
            <div className=" w-auto  h-14  flex flex-row justify-center items-center">
              {t('aboutOrg.downloadButton')}
            </div>
          </Button>
        </div>
      </Container>

      <Container>
        <div className=" w-full flex flex-row justify-center items-center ">
          {t('aboutOrg.howToEnroll')}
        </div>
        <div className=" flex flex-col"></div>
      </Container>
      <Container className="pt-0">
        <div className=" ">
          <div className=" flex flex-row gap-6 ">
            {cardData.map((content, index) => (
              <div key={index} className="">
                <Card className="rounded-sm border  min-h-[250px] bg-card">
                  <CardHeader className=" bg-card   min-h-64 lg:min-h-54 flex flex-col justify-between  ">
                    <div>
                      <img className="w-5 h-5 relative" alt="" src={download} />
                    </div>
                    <div className=" mb-1">
                      <CardTitle className="font-semibold text-lg mb-0">
                        {' '}
                        {content.title}
                      </CardTitle>
                      <p className=" text-sm mb-2  ">{content.description}</p>
                    </div>
                    <Button variant="secondary">
                      {t('aboutOrg.learnMore')}
                    </Button>
                  </CardHeader>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </>
  );
};

export default AboutOrg;
