import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import { motion } from 'framer-motion';

import { cn } from '../../lib/utils';

const buttonVariants = cva(
  'inline-flex min-h-11 items-center justify-center whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-primary rounded-full text-primary-foreground shadow-sm hover:bg-gray-700',
        destructive:
          'bg-red-300 rounded-full text-destructive-foreground shadow-sm hover:bg-destructive/90',
        outline:
          'border border-input bg-foreground text-background shadow-sm hover:bg-accent hover:text-accent-foreground',
        secondary:
          'bg-secondary rounded-full text-secondary-foreground hover:bg-none  hover:border hover:text-background',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-9 px-4 py-2',
        sm: 'h-8 rounded-full px-3 text-xs',
        lg: 'h-10 rounded-full px-8',
        icon: 'h-9 w-9',
        xl: 'h-12 w-12',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

const Button = React.forwardRef(
  ({ className, variant, size, asChild = false, href, ...props }, ref) => {
    const Comp = asChild ? Slot : href ? 'a' : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        href={href}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';
const MotionButton = motion(Button);

export { Button, buttonVariants, MotionButton };
